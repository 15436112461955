import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Sidebar.css';
import logo from './images/brand.png';
import coachPicture from './images/coach.jpg';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function Sidebar({ practiceDates = [] }) {
  const today = new Date();
  const [currentWeek, setCurrentWeek] = useState(today);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await fetch('http://localhost:3000/user/2826308f-4af0-41d3-a951-a2224f615f8a');
        console.log(response);
        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        console.error('error fetching user data');
      }
    }
    fetchUser();
  }, []);

  const startOfWeek = new Date(currentWeek);
  startOfWeek.setDate(currentWeek.getDate() - currentWeek.getDay());

  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    return {
      day: daysOfWeek[date.getDay()],
      date: date.getDate(),
      fullDate: date,
    };
  });

  const handlePreviousWeek = () => {
    const previousWeek = new Date(currentWeek);
    previousWeek.setDate(currentWeek.getDate() - 7);
    setCurrentWeek(previousWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(currentWeek.getDate() + 7);
    setCurrentWeek(nextWeek);
  };

  const isPracticeDay = (fullDate) => {
    return practiceDates.some((practiceDate) => new Date(practiceDate).toDateString() === fullDate.toDateString());
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      <div className="sidebar-info">
        {user ? (
          <>
            <img src={user.profilePicture} alt="Coach" className="profile-picture" />
            <div className="info-content">
              <h3>{user.name}</h3>
              <p>{user.location}</p>
              <p>{user.role}</p>
            </div>
          </>
        ) : (
          <p>Loading user...</p>
        )}
      </div>

      <div className="calendar-section">
        <h3>Weekly Schedule</h3>
        <div className="calendar-navigation">
          <button className="nav-button" onClick={handlePreviousWeek}>
            <FaChevronLeft />
          </button>
          <span>
            {startOfWeek.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} -{' '}
            {new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <button className="nav-button" onClick={handleNextWeek}>
            <FaChevronRight />
          </button>
        </div>
        <div className="calendar-vertical">
          {weekDays.map(({ day, date, fullDate }, index) => {
            const isToday = today.toDateString() === fullDate.toDateString();
            const hasPractice = isPracticeDay(fullDate);

            return (
              <div key={index} className={`calendar-day-vertical ${isToday ? 'today' : ''} ${hasPractice ? 'practice-day' : ''}`}>
                <span className="day-name">{day}</span>
                <span className="day-number">{date}</span>
                {hasPractice && <span className="practice-indicator">Practice</span>}
              </div>
            );
          })}
        </div>
      </div>

      <footer className="sidebar-footer">
        <p>&copy; 2024 GameDay.AI</p>
      </footer>
    </div>
  );
}

export default Sidebar;
