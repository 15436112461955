import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { FaBell, FaUser, FaCog, FaSun, FaMoon, FaUserTie, FaMoneyBillWave, FaQuestionCircle} from "react-icons/fa";
import "./Header.css";
//import logo from "./images/logo.png";

function Header() {
  const location = useLocation(); // Get the current URL path
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark-theme", theme === "dark");
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    setIsSettingsOpen(false);
  };
  const navigateToManageUsers = () => {};
  const navigateToManageSubs = () => {};
  const navigateToFAQ = () => {};
  return (
    <>
      <header className="header">
        <div className="nav-left">
          {/* Logo Section */}
          {/*<div className="logo-container">
          {/* <img src={logo} alt="Logo" className="logo" />
        </div>*/}
          {/* Navigation Links */}
          <nav className="nav-bar">
            <Link
              to="/"
              className={`nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/practice-plan"
              className={`nav-link ${
                location.pathname === "/practice-plan" ? "active" : ""
              }`}
            >
              Practice Plan
            </Link>
            <Link
              to="/drill-hub"
              className={`nav-link ${
                location.pathname === "/drill-hub" ? "active" : ""
              }`}
            >
              Drill Hub
            </Link>
            <Link
              to="/rosters"
              className={`nav-link ${
                location.pathname === "/rosters" ? "active" : ""
              }`}
            >
              Rosters
            </Link>
            <Link
              to="/players"
              className={`nav-link ${
                location.pathname === "/players" ? "active" : ""
              }`}
            >
              Players
            </Link>
          </nav>
        </div>
        <div className="nav-right">
          <Link to="/notifications" className="nav-icon-link">
            <FaBell className="nav-icon" title="Notifications" />
          </Link>
          <div className="settings-dropdown" ref={dropdownRef}>
            {isSettingsOpen && (
              <div
                className={`dropdown-menu ${
                  theme === "dark" ? "dark-theme" : ""
                }`}
                style={{
                  position: "fixed",
                  top: dropdownRef.current
                    ? dropdownRef.current.getBoundingClientRect().bottom + 8
                    : 0,
                  right: dropdownRef.current
                    ? window.innerWidth -
                      dropdownRef.current.getBoundingClientRect().right
                    : 0,
                  zIndex: 9999,
                }}
              >
                <button onClick={toggleTheme} className="dropdown-item">
                  {theme === "light" ? (
                    <FaMoon className="dropdown-icon" />
                  ) : (
                    <FaSun className="dropdown-icon" />
                  )}
                  {theme === "light" ? "Dark Mode" : "Light Mode"}
                </button>
                <button
                  onClick={navigateToManageUsers}
                  className="dropdown-item"
                >
                  <FaUserTie className="dropdown-icon" />
                  Manage Users
                </button>
                <button
                  onClick={navigateToManageSubs}
                  className="dropdown-item"
                >
                  <FaMoneyBillWave className="dropdown-icon" />
                  Manage Subscription
                </button>
                <button onClick={navigateToFAQ} className="dropdown-item">
                  <FaQuestionCircle className="dropdown-icon" />
                  FAQ
                </button>
              </div>
            )}
            <button
              className="nav-icon-button"
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}
              aria-haspopup="true"
              aria-expanded={isSettingsOpen}
            >
              <FaCog className="nav-icon" title="Settings" />
            </button>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
