import React, { useState, useEffect, useRef } from "react";
import { FaSearch, FaEdit, FaClone, FaTrash, FaFootballBall } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import PracticeScheduleForm from "./PracticeScheduleForm";
import PracticeScheduleReview from "./PracticeScheduleReview";
import "./PracticePlan.css";

function PracticePlan() {
  const [activeTab, setActiveTab] = useState("template");
  const [showForm, setShowForm] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [formData, setFormData] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [upcomingPractices, setUpcomingPractices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewDrillDetails, setViewDrillDetails] = useState(null);
  const navigate = useNavigate();
  const printRef = useRef();

  // Fetch upcoming practices from localStorage
  useEffect(() => {
    if (activeTab === "schedule") {
      const savedPractice = localStorage.getItem("upcomingPractice");
      if (savedPractice) {
        setUpcomingPractices([JSON.parse(savedPractice)]);
      }
    }
  }, [activeTab]);

  const handleFormSubmit = (data) => {
    console.log(JSON.stringify(data));
    setFormData(data);
    setShowForm(false);
    setShowReview(true);
  };

  const handleCreateTemplate = (template) => {
    setTemplates((prevTemplates) => [...prevTemplates, template]);
    setShowReview(false);
    setShowForm(false);
  };

  const handleCreateClick = () => {
    setFormData({
      templateName: "",
      date: "",
      startTime: "08:00",
      endTime: "10:00",
      selectedPlayers: ["Group A", "Group B"],
      notes: "",
      timeIntervals: [],
    });
    setShowForm(true);
    setShowReview(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleDuplicate = (template) => {
    const duplicatedTemplate = {
      ...template,
      templateName: `${template.templateName} (Copy)`,
    };
    setTemplates((prevTemplates) => [...prevTemplates, duplicatedTemplate]);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this practice plan?")) {
      setTemplates((prevTemplates) =>
        prevTemplates.filter((_, idx) => idx !== index)
      );
    }
  };

  const handleView = (template) => {
    navigate("/practice-details", { state: { template } });
  };

  const handlePrint = () => {
    const content = printRef.current;
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
  
    // Scale the content to fit A4 size
    pdf.html(content, {
      callback: function (doc) {
        doc.save("Upcoming_Practice.pdf");
      },
      x: 10,
      y: 10,
      width: 190, // A4 width minus margins (10mm left and right)
      windowWidth: 675, // Adjust based on screen size for accurate scaling
    });
  };
  
  

  const filteredTemplates = templates.filter((template) =>
    template.templateName.toLowerCase().includes(searchQuery)
  );

  const formatTime = (time) => {
    if (!time) return "N/A";
    const [hour, minute] = time.split(":");
    const isPM = hour >= 12;
    const formattedHour = isPM ? hour % 12 || 12 : parseInt(hour, 10);
    const period = isPM ? "PM" : "AM";
    return `${formattedHour}:${minute} ${period}`;
  };

  const mergeVerticalCells = (intervals, player) => {
    const merged = [];
    let previous = null;

    intervals.forEach((interval, idx) => {
      const assignment = interval.assignments?.[player]?.activity;
      const color = interval.assignments?.[player]?.color;
      if (
        previous &&
        assignment === previous.assignment &&
        color === previous.color
      ) {
        previous.rowSpan += 1;
      } else {
        if (previous) merged.push(previous);
        previous = { index: idx, rowSpan: 1, assignment, color };
      }
    });

    if (previous) merged.push(previous);
    return merged;
  };

  return (
    <div className="practice-plan">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "template" ? "active-tab" : ""}`}
          onClick={() => {
            setActiveTab("template");
            setShowForm(false);
            setShowReview(false);
          }}
        >
          Practice Schedule
        </button>
        <button
          className={`tab ${activeTab === "schedule" ? "active-tab" : ""}`}
          onClick={() => {
            setActiveTab("schedule");
            setShowForm(false);
            setShowReview(false);
          }}
        >
          Upcoming Practices
        </button>
      </div>

      <div className="tab-content">
        {/* Practice Templates Section */}
        {activeTab === "template" && !showForm && !showReview && (
          <div className="template-section">
            <h3>Practice Schedule</h3>
            <div className="actions">
              <button className="create-button" onClick={handleCreateClick}>
                + Create
              </button>
              <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search practice plans"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {filteredTemplates.length > 0 ? (
              <table className="styled-table" style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black" }}>Practice Name</th>
                    <th style={{ border: "1px solid black" }}>Date</th>
                    <th style={{ border: "1px solid black" }}>Start Time</th>
                    <th style={{ border: "1px solid black" }}>End Time</th>
                    <th style={{ border: "1px solid black" }}>Notes</th>
                    <th style={{ border: "1px solid black" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTemplates.map((template, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black" }}>{template.templateName}</td>
                      <td style={{ border: "1px solid black" }}>{template.date}</td>
                      <td style={{ border: "1px solid black" }}>{formatTime(template.startTime)}</td>
                      <td style={{ border: "1px solid black" }}>{formatTime(template.endTime)}</td>
                      <td style={{ border: "1px solid black" }}>{template.notes || "No notes"}</td>
                      <td style={{ border: "1px solid black" }}>
                        <button onClick={() => handleView(template)}><FaEdit /></button>&nbsp;
                        <button onClick={() => handleDuplicate(template)}><FaClone /></button>&nbsp;
                        <button onClick={() => handleDelete(index)}><FaTrash /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No practice plans available.</p>
            )}
          </div>
        )}

        {/* Show Practice Form */}
        {showForm && (
          <PracticeScheduleForm onFormSubmit={handleFormSubmit} />
        )}

        {/* Show Practice Review */}
        {showReview && formData && (
          <PracticeScheduleReview
            formData={formData}
            onCreateTemplate={handleCreateTemplate}
            onGoBack={() => {
              setShowReview(false);
              setShowForm(true);
            }}
          />
        )}

        {/* Upcoming Practices Section */}
        {activeTab === "schedule" && (
          <div className="upcoming-practices" ref={printRef}>
            <h3>Upcoming Practices</h3>
            <button onClick={handlePrint} style={{ marginBottom: "10px" }}>
              Print / Download PDF
            </button>
            {upcomingPractices.length > 0 ? (
              upcomingPractices.map((practice, index) => (
                <div key={index} className="practice-details">
                  <h4>Practice Name: {practice.practiceName}</h4>
                  <p>Date: {practice.practiceDate}</p>
                  <p>Start Time: {formatTime(practice.startTime)}</p>
                  <p>End Time: {formatTime(practice.endTime)}</p>
                  <p>Notes: {practice.notes || "No notes provided."}</p>
                  <h5>Practice Schedule</h5>
                  <table className="time-interval-table" style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid black" }}>Time</th>
                        {practice.selectedPlayers.map((player, idx) => (
                          <th key={idx} style={{ border: "1px solid black" }}>{player}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {practice.timeIntervals.map((interval, rowIndex) => (
                        <tr key={rowIndex}>
                          <td style={{ border: "1px solid black" }}>{interval.time}</td>
                          {practice.selectedPlayers.map((player, playerIdx) => {
                            const mergedCells = mergeVerticalCells(practice.timeIntervals, player);
                            const cell = mergedCells.find((cell) => cell.index === rowIndex);

                            if (!cell || cell.index !== rowIndex) return null;
                            return (
                              <td
                                key={playerIdx}
                                rowSpan={cell.rowSpan}
                                style={{
                                  border: "1px solid black",
                                  backgroundColor: cell.color || "#fff",
                                  color: cell.color === "#000000" ? "#fff" : "#000",
                                  textAlign: "center",
                                  position: "relative",
                                }}
                              >
                                {cell.assignment}
                                {cell.assignment && (
                                  <FaFootballBall
                                    className="drill-icon"
                                    style={{ cursor: "pointer", marginLeft: "5px" }}
                                    onClick={() => setViewDrillDetails(cell.assignment)}
                                  />
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {viewDrillDetails && (
                    <div className="modal-overlay">
                      <div className="modal">
                        <h3>Drill Details</h3>
                        <p>{viewDrillDetails}</p>
                        <button onClick={() => setViewDrillDetails(null)}>Close</button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No upcoming practices scheduled.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PracticePlan;
